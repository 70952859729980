import { useNavigate } from 'react-router';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { alpha, useTheme } from '@mui/material/styles';

import { bgBlur } from 'src/theme/css';
import Logo from 'src/components/logo';
import { usePathname } from 'src/routes/hooks';
import { useResponsive } from 'src/hooks/use-responsive';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useFirebaseContext } from 'src/sections/auth/firebase-context';

import { HEADER } from '../config-layout';
import HeaderShadow from '../common/header-shadow';

import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { navConfig } from './config-navigation';

// ----------------------------------------------------------------------

type Props = {
  headerOnDark: boolean;
};

export default function Header({ headerOnDark }: Props) {
  const theme = useTheme();

  const offset = useOffSetTop();
  // const pathname = usePathname();

  const mdUp = useResponsive('up', 'md');
  // const { logout, currentUser } = useFirebaseContext();
  // const navigate = useNavigate();
  // const onLogout = async () => {
  //   await logout();
  //   console.log(currentUser);
  //   navigate('auth/login-cover');
  // };
  // const isHome = pathname === '/';

  return (
    <AppBar
      sx={{
        zIndex: 1500,
        // backgroundColor: alpha(theme.palette.common.black, isHome ? 0.4 : 0.7),
        color: theme.palette.common.white,
        ...bgBlur({ color: theme.palette.common.black, opacity: 0.25, blur: 3 }),
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),

          ...(headerOnDark && {
            color: 'common.white',
          }),
          ...(offset && {
            // // ...bgBlur({ color: theme.palette.background.default }),
            // ...bgBlur({ color: theme.palette.common.black }),
            // // color: 'text.primary',
            // // color: 'text.secondary',
            // color: theme.palette.grey[500],
            // height: {
            //   md: HEADER.H_DESKTOP - 16,
            // },
            ...bgBlur({ color: theme.palette.common.black, opacity: 0.2, blur: 6 }),
          }),
        }}
      >
        <Container
          sx={{
            // height: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            minWidth: '100%',
            // color: 'white',
          }}
        >
          <Box mb={2} mt={2} pr={4}>
            <Logo w={56} h={56} withText={false} />
          </Box>

          {mdUp && <NavDesktop data={navConfig} />}

          <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
            {/* <Stack spacing={1} direction="row" alignItems="center">
              <Button
                sx={{ ml: 4, width: 'max-content', borderColor: theme.palette.common.white }}
                variant="outlined"
                color="inherit"
                rel="noopener"
                onClick={onLogout}
              >
                LOG OUT {currentUser?.email}
              </Button>
            </Stack> */}

            {/* {mdUp && (
              <Button
                variant="contained"
                color="inherit"
                href={paths.zoneStore}
                target="_blank"
                rel="noopener"
              >
                Buy Now
              </Button>
            )} */}
          </Stack>

          {!mdUp && <NavMobile data={navConfig} />}
        </Container>
      </Toolbar>

      {offset && <HeaderShadow />}
    </AppBar>
  );
}
