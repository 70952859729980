import { useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Portal from '@mui/material/Portal';
import Grid from '@mui/material/Unstable_Grid2';
import { ClickAwayListener } from '@mui/material';

import Image from 'src/components/image';
import Label from 'src/components/label';
import { usePathname } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { RouterLink } from 'src/routes/components';
import { useActiveLink } from 'src/routes/hooks/use-active-link';

import { NavListProps, NavItemBaseProps } from '../types';

import { NavItem } from './nav-item';
import { StyledMenu, StyledSubheader } from './styles';

// ----------------------------------------------------------------------

export default function NavList({ item }: { item: NavItemBaseProps }) {
  const pathname = usePathname();

  const menuOpen = useBoolean();

  const active = useActiveLink(item.path, false);

  const externalLink = item.path.includes('http');

  const mainList = item.children ? item.children.filter((list) => list.subheader !== 'Common') : [];

  const commonList = item.children
    ? item.children.find((list) => list.subheader === 'Common')
    : null;

  useEffect(() => {
    if (menuOpen.value) {
      menuOpen.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpenMenu = useCallback(() => {
    // if (menuOpen.value) menuOpen.onFalse();
    if (item.children) {
      menuOpen.onTrue();
    } else {
      menuOpen.onFalse();
    }
  }, [item.children, menuOpen]);

  return (
    <Box
      id="nav-list-parent-box"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      borderRadius={2}
    >
      <NavItem
        item={item}
        active={active}
        open={menuOpen.value}
        externalLink={externalLink}
        onMouseEnter={handleOpenMenu}
        onMouseLeave={() => {
          // console.log('nav item onLeave');
          menuOpen.onFalse();
        }}
        // onMouseLeave={handleOpenMenu}
      />
      {!!item.children && menuOpen.value && (
        <Fade in={menuOpen.value}>
          <StyledMenu
            sx={{ width: 'inherit', borderRadius: 2, maxHeight: '30rem', overflowY: 'scroll' }}
            onMouseEnter={handleOpenMenu}
            onMouseLeave={() => {
              // console.log('styled menu onLeave');
              menuOpen.onFalse();
            }}
            // onMouseLeave={menuOpen.onTrue}
          >
            <Grid id="styled-menu-grid" container columns={1} borderRadius={2}>
              <Grid xs={12}>
                <Box
                  gap={2}
                  display="grid"
                  // gridTemplateColumns="repeat(5, 1fr)"
                  sx={{
                    px: 4,
                    py: 2,
                    mx: 'auto',
                    height: 1,
                    position: 'relative',
                    borderRadius: 4,
                  }}
                >
                  {mainList.map((list, idx) => (
                    <NavSubList
                      key={list.subheader || idx}
                      // subheader={list.subheader}
                      // cover={list.cover}
                      items={list.items}
                      isNew={list.isNew}
                    />
                  ))}
                </Box>
              </Grid>

              {commonList && (
                <Grid xs={3}>
                  <Box sx={{ bgcolor: 'background.default', p: 5 }}>
                    <NavSubList subheader={commonList.subheader} items={commonList.items} />
                  </Box>
                </Grid>
              )}
            </Grid>
          </StyledMenu>
        </Fade>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

function NavSubList({ subheader, isNew, cover, items }: NavListProps) {
  const pathname = usePathname();

  const coverPath = items.length ? items[0].path : '';

  const commonList = subheader === 'Common';

  return (
    <Stack spacing={1}>
      {/* <StyledSubheader>
        {subheader}
        {isNew && (
          <Label color="info" sx={{ ml: 1 }}>
            NEW
          </Label>
        )}
      </StyledSubheader> */}

      {/* {!commonList && (
        <Link component={RouterLink} href={coverPath}>
          <Image
            disabledEffect
            alt={cover}
            src={cover || '/assets/placeholder.svg'}
            ratio="16/9"
            sx={{
              borderRadius: 1,
              cursor: 'pointer',
              boxShadow: (theme) => theme.customShadows.z8,
              transition: (theme) => theme.transitions.create('all'),
              '&:hover': {
                opacity: 0.8,
                boxShadow: (theme) => theme.customShadows.z24,
              },
            }}
          />
        </Link>
      )} */}

      <Stack spacing={1} alignItems="flex-start" width={'10rem'}>
        {items.map((item) => {
          const active = pathname === item.path || pathname === `${item.path}/`;

          return <NavItem key={item.title} item={item} active={active} subItem />;
        })}
      </Stack>
    </Stack>
  );
}
