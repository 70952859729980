import { useMemo } from 'react';
import merge from 'lodash.merge';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeOptions, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { useSettingsContext } from 'src/components/settings';

import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';
import { presets } from './options/presets';
import { darkMode } from './options/dark-mode';
import { customShadows } from './custom-shadows';
import { componentsOverrides } from './overrides';
import RTL, { direction } from './options/right-to-left';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

interface CustomBreakpointValueType {
  xs: number;
  xsPlus?: number;
  sm: number;
  smPlus?: number;
  md: number;
  mdPlus?: number;
  lg: number;
  lgPlus: number;
  xl: number;
}
interface CustomBreakpointOptions {
  values: CustomBreakpointValueType;
}
declare module '@mui/material/styles' {
  interface Theme {
    customBreakpoints: CustomBreakpointOptions;
  }
  interface ThemeOptions {
    breakpoints?: CustomBreakpointOptions;
  }
}

export default function ThemeProvider({ children }: Props) {
  const settings = useSettingsContext();

  const darkModeOption = darkMode(settings.themeMode);

  const presetsOption = presets(settings.themeColorPresets);

  const directionOption = direction(settings.themeDirection);

  const baseOption = useMemo(
    () => ({
      palette: palette('light'),
      shadows: shadows('light'),
      customShadows: customShadows('light'),
      typography,
      shape: { borderRadius: 8 },
    }),
    []
  );

  const memoizedValue = useMemo(
    () =>
      merge(
        // Base
        baseOption,
        // Direction: remove if not in use
        directionOption,
        // Dark mode: remove if not in use
        darkModeOption,
        // Presets: remove if not in use
        presetsOption
      ),
    [baseOption, darkModeOption, directionOption, presetsOption]
  );

  const theme = createTheme({
    ...(memoizedValue as ThemeOptions),
    breakpoints: {
      values: {
        xs: 0,
        xsPlus: 500,
        sm: 750,
        smPlus: 860,
        md: 1130,
        mdPlus: 1290,
        lg: 1400,
        lgPlus: 1600,
        xl: 1920,

        /* default values */
        // xs: 0,
        // sm: 600,
        // md: 900,
        // lg: 1200,
        // xl: 1536,
      },
    },
  });

  theme.components = componentsOverrides(theme);

  return (
    <MuiThemeProvider theme={theme}>
      <RTL themeDirection={settings.themeDirection}>
        <CssBaseline />
        {children}
      </RTL>
    </MuiThemeProvider>
  );
}
