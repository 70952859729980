import { lazy, Suspense, useEffect, useState } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import MainLayout from 'src/layouts/main';
// import WhatIsKamboPage from 'src/pages/what-is-kambo';
import { SplashScreen } from 'src/components/loading-screen';
import client from 'src/sanity-client';
import { urlConfigsQuery } from 'src/queries';

import AuthGuard from '../route-guards/AuthGuard';

import { authRoutes } from './auth';
import { errorRoutes } from './error';
// import { travelRoutes } from './travel';
import { commonRoutes } from './common';
// import { careerRoutes } from './career';
// import { marketingRoutes } from './marketing';
// import { eLearningRoutes } from './elearning';
import { eCommerceRoutes } from './ecommerce';
// import { componentsRoutes } from './components';
// import Playground from 'src/pages/playground/playground';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/home'));
const SupportPage = lazy(() => import('src/pages/support'));
const Playground = lazy(() => import('src/pages/playground'));
const WhatIsKamboPage = lazy(() => import('src/pages/what-is-kambo'));
const EventList = lazy(() => import('src/pages/events'));
const PractitionerSchools = lazy(() => import('src/pages/all-schools'));
const AboutOurSchool = lazy(() => import('src/pages/about-our-school'));
const SchoolsFAQ = lazy(() => import('src/pages/school-faq'));
const Practitioners = lazy(() => import('src/pages/practitioners'));
const ImageGallery = lazy(() => import('src/pages/gallery'));
const PrivacyPolicy = lazy(() => import('src/pages/privacy-policy'));
const TermsOfService = lazy(() => import('src/pages/terms-of-service'));
const Reviews = lazy(() => import('src/pages/reviews'));
const Docs = lazy(() => import('src/pages/documentation'));

export default function Router() {
  return useRoutes([
    {
      element: (
        <AuthGuard>
          <MainLayout>
            <Suspense fallback={<SplashScreen />}>
              <Outlet />
            </Suspense>
          </MainLayout>
        </AuthGuard>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'support', element: <SupportPage /> },
        { path: 'what-is-kambo', element: <WhatIsKamboPage /> },
        // { path: 'event-list', element: <EventList /> },
        { path: 'schools', element: <PractitionerSchools /> },
        { path: 'about-our-school', element: <AboutOurSchool /> },
        { path: 'schools-faq', element: <SchoolsFAQ /> },
        { path: 'practitioners', element: <Practitioners /> },
        // { path: 'private-training', element: <Practitioners /> },
        { path: 'gallery', element: <ImageGallery /> },
        { path: 'reviews', element: <Reviews /> },
        { path: 'docs/$jf12345$', element: <Docs /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'terms-of-service', element: <TermsOfService /> },

        ...eCommerceRoutes,

        // ...componentsRoutes,

        { path: 'playground', element: <Playground /> },
      ],
    },

    ...errorRoutes,

    // ...commonRoutes,

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
