import Box from '@mui/material/Box';

import { usePathname } from 'src/routes/hooks';
import { useTheme } from '@mui/material/styles';
import { HEADER } from '../config-layout';

import Header from './header';
import Footer from './footer';

// ----------------------------------------------------------------------

const pathsOnDark = ['/career', '/career/', '/travel', '/travel/'];

const spacingLayout = [
  ...pathsOnDark,
  '/',
  '/e-learning',
  '/e-learning/',
  '/marketing',
  '/marketing/',
];

type Props = {
  children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
  const pathname = usePathname();
  const theme = useTheme();

  const actionPage = (arr: string[]) => arr.some((path) => pathname === path);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 1,
        backgroundColor: 'black',
      }}
    >
      <Header headerOnDark={actionPage(pathsOnDark)} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: 'black',
          // mb: { xs: 2, md: 6 },
        }}
      >
        {/* {!actionPage(spacingLayout) && <Spacing backgroundColor={theme.palette.common.black} />} */}
        {children}
      </Box>

      <Footer />
    </Box>
  );
}

// ----------------------------------------------------------------------

type SpacingProps = {
  backgroundColor: string;
};

function Spacing({ backgroundColor }: SpacingProps) {
  return (
    <Box
      sx={{
        height: { xs: HEADER.H_MOBILE, md: HEADER.H_DESKTOP },
        backgroundColor,
      }}
    />
  );
}
