import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export const pageLinks = [
  {
    order: '1',
    subheader: 'Marketing',
    cover: '/assets/images/menu/menu_marketing.jpg',
    items: [
      { title: 'Landing', path: paths.marketing.root },
      { title: 'Services', path: paths.marketing.services },
      { title: 'Case Studies', path: paths.marketing.caseStudies },
      { title: 'Case Study', path: paths.marketing.caseStudy },
      { title: 'Blog Posts', path: paths.marketing.posts },
      { title: 'Blog Post', path: paths.marketing.post },
      { title: 'About', path: paths.marketing.about },
      { title: 'Contact', path: paths.marketing.contact },
    ],
  },
  {
    order: '6',
    subheader: 'Travel',
    cover: '/assets/images/menu/menu_travel.jpg',
    items: [
      { title: 'Landing', path: paths.travel.root },
      { title: 'Tours', path: paths.travel.tours },
      { title: 'Tour', path: paths.travel.tour },
      { title: 'Checkout', path: paths.travel.checkout },
      { title: 'Order Completed', path: paths.travel.orderCompleted },
      { title: 'Blog Posts', path: paths.travel.posts },
      { title: 'Blog Post', path: paths.travel.post },
      { title: 'About', path: paths.travel.about },
      { title: 'Contact', path: paths.travel.contact },
    ],
  },
  {
    order: '2',
    subheader: 'Career',
    cover: '/assets/images/menu/menu_career.jpg',
    items: [
      { title: 'Landing', path: paths.career.root },
      { title: 'Jobs', path: paths.career.jobs },
      { title: 'Job', path: paths.career.job },
      { title: 'Blog Posts', path: paths.career.posts },
      { title: 'Blog Post', path: paths.career.post },
      { title: 'About', path: paths.career.about },
      { title: 'Contact', path: paths.career.contact },
    ],
  },
  {
    order: '5',
    subheader: 'E-learning',
    cover: '/assets/images/menu/menu_elearning.jpg',
    items: [
      { title: 'Landing', path: paths.eLearning.root },
      { title: 'Courses', path: paths.eLearning.courses },
      { title: 'Course', path: paths.eLearning.course },
      { title: 'Blog Posts', path: paths.eLearning.posts },
      { title: 'Blog Post', path: paths.eLearning.post },
      { title: 'About', path: paths.eLearning.about },
      { title: 'Contact', path: paths.eLearning.contact },
    ],
  },
  {
    isNew: true,
    order: '3',
    subheader: 'E-commerce',
    cover: '/assets/images/menu/menu_ecommerce.jpg',
    items: [
      { title: 'Landing', path: paths.eCommerce.root },
      { title: 'Products', path: paths.eCommerce.products },
      { title: 'Product', path: paths.eCommerce.product },
      { title: 'Cart', path: paths.eCommerce.cart },
      { title: 'Checkout', path: paths.eCommerce.checkout },
      { title: 'Order Completed', path: paths.eCommerce.orderCompleted },
      { title: 'Wishlist', path: paths.eCommerce.wishlist },
      { title: 'Compare', path: paths.eCommerce.compare },
      { title: 'Account Personal', path: paths.eCommerce.account.personal },
      { title: 'Account Wishlist', path: paths.eCommerce.account.wishlist },
      { title: 'Account Vouchers', path: paths.eCommerce.account.vouchers },
      { title: 'Account Orders', path: paths.eCommerce.account.orders },
      { title: 'Account Payment', path: paths.eCommerce.account.payment },
    ],
  },
  {
    order: '4',
    subheader: 'Common',
    items: [
      { title: 'Login Cover', path: paths.loginCover },
      { title: 'Login Illustration', path: paths.loginIllustration },
      { title: 'Login Background', path: paths.loginBackground },
      { title: 'Register Cover', path: paths.registerCover },
      { title: 'Register Illustration', path: paths.registerIllustration },
      { title: 'Register Background', path: paths.registerBackground },
      { title: 'Forgot Password', path: paths.forgotPassword },
      { title: 'Verify Code', path: paths.verify },
      { title: '404 Error', path: paths.page404 },
      { title: '500 Error', path: paths.page500 },
      { title: 'Maintenance', path: paths.maintenance },
      { title: 'ComingSoon', path: paths.comingsoon },
      { title: 'Pricing 01', path: paths.pricing01 },
      { title: 'Pricing 02', path: paths.pricing02 },
      { title: 'Payment', path: paths.payment },
      { title: 'Support', path: paths.support },
    ],
  },
];

export const navConfig = [
  { title: 'HOME', path: '/' },
  // { title: 'Components', path: paths.components.root },
  { title: 'WHAT IS KAMBO?', path: paths.whatIsKambo },
  {
    title: 'KAMBO PRACTITIONER SCHOOL',
    path: paths.kamboPractitionerSchool.root,
    children: [
      {
        order: '1',
        // subheader: 'School Calendar',
        // cover: '/assets/images/menu/menu_marketing.jpg',
        items: [
          // { title: 'School Calendar', path: paths.kamboPractitionerSchool.calendar },
          { title: 'About Our School', path: paths.kamboPractitionerSchool.about },
          { title: 'Find a School', path: paths.kamboPractitionerSchool.root },
          { title: 'FAQ', path: paths.kamboPractitionerSchool.faq },

          // { title: 'Private Training', path: paths.kamboPractitionerSchool.privateTraining },
          // { title: 'Reviews', path: paths.reviews },
        ],
      },
    ],
  },

  {
    title: 'FIND A PRACTITIONER',
    path: paths.practitioners.root,
  },

  {
    title: 'MORE',
    path: '',
    children: [
      {
        order: '1',
        items: [{ title: 'Tribal Detox Store', path: paths.eCommerce.products }],
      },
      {
        order: '2',
        items: [{ title: 'Media Gallery', path: paths.gallery }],
      },
      {
        order: '3',
        items: [{ title: 'Reviews', path: paths.reviews }],
      },
    ],
  },

  // {
  //   title: 'PRACTITIONERS IN USA',
  //   path: paths.practitionersInUSA.root,
  //   children: [
  //     {
  //       order: '2',
  //       // subheader: 'School Calendar',
  //       // cover: '/assets/images/menu/menu_marketing.jpg',
  //       items: [
  //         { title: 'Alabama', path: paths.practitionersInUSA.regions.alabama },
  //         { title: 'Arizona', path: paths.practitionersInUSA.regions.arizona },
  //         { title: 'Arkansas', path: paths.practitionersInUSA.regions.arkansas },
  //         { title: 'California', path: paths.practitionersInUSA.regions.california },
  //         { title: 'Colorado', path: paths.practitionersInUSA.regions.colorado },
  //         { title: 'Connecticut', path: paths.practitionersInUSA.regions.connecticut },
  //         { title: 'Florida', path: paths.practitionersInUSA.regions.florida },
  //         { title: 'Georgia', path: paths.practitionersInUSA.regions.georgia },
  //         { title: 'Hawaii', path: paths.practitionersInUSA.regions.hawaii },
  //         { title: 'Idaho', path: paths.practitionersInUSA.regions.idaho },
  //         { title: 'Illinois', path: paths.practitionersInUSA.regions.illinois },
  //         { title: 'Louisiana', path: paths.practitionersInUSA.regions.louisiana },
  //         { title: 'Maryland', path: paths.practitionersInUSA.regions.maryland },
  //         { title: 'Michigan', path: paths.practitionersInUSA.regions.michigan },
  //         { title: 'Mississippi', path: paths.practitionersInUSA.regions.mississippi },
  //         { title: 'Missouri', path: paths.practitionersInUSA.regions.missouri },
  //         { title: 'Minnesota', path: paths.practitionersInUSA.regions.minnesota },
  //         { title: 'Montana', path: paths.practitionersInUSA.regions.montana },
  //         { title: 'Nebraska', path: paths.practitionersInUSA.regions.nebraska },
  //         { title: 'Nevada', path: paths.practitionersInUSA.regions.nevada },
  //         { title: 'New Jersey', path: paths.practitionersInUSA.regions.newJersey },
  //         { title: 'New Mexico', path: paths.practitionersInUSA.regions.newMexico },
  //         { title: 'New York', path: paths.practitionersInUSA.regions.newYork },
  //         { title: 'North Carolina', path: paths.practitionersInUSA.regions.northCarolina },
  //         { title: 'Ohio', path: paths.practitionersInUSA.regions.ohio },
  //         { title: 'Oklahoma', path: paths.practitionersInUSA.regions.oklahoma },
  //         { title: 'Oregon', path: paths.practitionersInUSA.regions.oregon },
  //         { title: 'Pennsylvania', path: paths.practitionersInUSA.regions.pennsylvania },
  //         { title: 'Rhode Island', path: paths.practitionersInUSA.regions.rhodeIsland },
  //         { title: 'Tennessee', path: paths.practitionersInUSA.regions.tennessee },
  //         { title: 'Texas', path: paths.practitionersInUSA.regions.texas },
  //         { title: 'Utah', path: paths.practitionersInUSA.regions.utah },
  //         { title: 'Vermont', path: paths.practitionersInUSA.regions.vermont },
  //         { title: 'Virginia', path: paths.practitionersInUSA.regions.virginia },
  //         { title: 'Washington State', path: paths.practitionersInUSA.regions.washingtonState },
  //         { title: 'Wisconsin', path: paths.practitionersInUSA.regions.wisconsin },
  //         { title: 'Wyoming', path: paths.practitionersInUSA.regions.wyoming },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   title: 'PAGES',
  //   path: paths.pages,
  //   children: [pageLinks[0], pageLinks[1], pageLinks[2], pageLinks[3], pageLinks[4], pageLinks[5]],
  // },
  // { title: 'DOCS', path: paths.docs },

  // { title: 'PLAYGROUND', path: '/playground' },
];
