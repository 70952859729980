// ----------------------------------------------------------------------

export const paths = {
  // Marketing
  marketing: {
    root: '/marketing',
    services: '/marketing/services',
    caseStudies: '/marketing/case-studies',
    caseStudy: `/marketing/case-study`,
    posts: '/marketing/posts',
    post: `/marketing/post`,
    about: '/marketing/about',
    contact: '/marketing/contact',
  },
  // Travel
  travel: {
    root: '/travel',
    tours: '/travel/tours',
    tour: `/travel/tour`,
    checkout: '/travel/checkout',
    orderCompleted: '/travel/order-completed',
    posts: '/travel/posts',
    post: `/travel/post`,
    about: '/travel/about',
    contact: '/travel/contact',
  },
  // Career
  career: {
    root: '/career',
    jobs: '/career/jobs',
    job: `/career/job`,
    posts: '/career/posts',
    post: `/career/post`,
    about: '/career/about',
    contact: '/career/contact',
  },
  // E-learning
  eLearning: {
    root: '/e-learning',
    courses: '/e-learning/courses',
    course: `/e-learning/course`,
    posts: '/e-learning/posts',
    post: `/e-learning/post`,
    about: '/e-learning/about',
    contact: '/e-learning/contact',
  },
  // E-commerce
  eCommerce: {
    root: '/e-commerce',
    products: '/e-commerce/products',
    product: `/e-commerce/product`,
    cart: '/e-commerce/cart',
    checkout: `/e-commerce/checkout`,
    orderCompleted: '/e-commerce/order-completed',
    wishlist: `/e-commerce/wishlist`,
    compare: `/e-commerce/compare`,
    account: {
      root: `/e-commerce/account`,
      personal: `/e-commerce/account/personal`,
      wishlist: `/e-commerce/account/wishlist`,
      vouchers: `/e-commerce/account/vouchers`,
      orders: `/e-commerce/account/orders`,
      payment: `/e-commerce/account/payment`,
    },
  },
  // Auth
  loginCover: '/auth/login-cover',
  loginBackground: '/auth/login-background',
  loginIllustration: '/auth/login-illustration',
  registerCover: '/auth/register-cover',
  registerBackground: '/auth/register-background',
  registerIllustration: '/auth/register-illustration',
  forgotPassword: '/auth/forgot-password',
  verify: '/auth/verify',
  // Common
  maintenance: '/maintenance',
  comingsoon: '/coming-soon',
  pricing01: '/pricing-01',
  pricing02: '/pricing-02',
  payment: '/payment',
  support: '/support',
  page404: '/404',
  page500: '/500',
  // Components
  components: {
    root: '/components',
    animate: '/components/animate',
    carousel: '/components/carousel',
    countUp: '/components/count-up',
    form: '/components/form-validation',
    icons: '/components/icons',
    image: '/components/image',
    label: '/components/label',
    lightbox: '/components/lightbox',
    markdown: '/components/markdown',
    megaMenu: '/components/mega-menu',
    navigation: '/components/navigation-bar',
    scroll: '/components/scroll',
    player: '/components/player',
    textMaxLine: '/components/text-max-line',
  },
  gallery: '/gallery',
  whatIsKambo: '/what-is-kambo',
  eventList: '/event-list',
  reviews: '/reviews',
  kamboPractitionerSchool: {
    root: '/schools',
    about: '/about-our-school',
    calendar: '/event-list', // this is the working one
    // calendar: '/event-calendar', // this is the demo one
    faq: '/schools-faq', // this is the working one
    // faq: '/schools-faqs', // this is the demo one
    // register: '/schools/register',
    creedeCO: '/creede-colorado',
    austinTXMadison: '/austin-tx-madison',
    austinTXCamikambo: '/austin-tx-camikambo',
    texarkana: '/texarkana',
    unitedKingdom: '/united-kingdom',
    privateTraining: '/private-training',
  },
  practitioners: {
    root: '/practitioners',
    regions: {
      alabama: '/alabama',
      arizona: '/arizona',
      arkansas: '/arkansas',
      california: '/california',
      colorado: '/colorado',
      connecticut: '/connecticut',
      florida: '/florida',
      georgia: '/georgia',
      hawaii: '/hawaii',
      idaho: '/idaho',
      illinois: '/illinois',
      louisiana: '/louisiana',
      maryland: '/maryland',
      michigan: '/michigan',
      mississippi: '/mississippi',
      missouri: '/missouri',
      minnesota: '/minnesota',
      montana: '/montana',
      nebraska: '/nebraska',
      nevada: '/nevada',
      newJersey: '/newJersey',
      newMexico: '/newMexico',
      newYork: '/newYork',
      northCarolina: '/north-carolina',
      ohio: '/ohio',
      oklahoma: '/oklahoma',
      oregon: '/oregon',
      pennsylvania: '/pennsylvania',
      rhodeIsland: '/rhodeIsland',
      tennessee: '/tennessee',
      texas: '/texas',
      utah: '/utah',
      vermont: '/vermont',
      virginia: '/virginia',
      washingtonState: '/washington-state',
      wisconsin: '/wisconsin',
      wyoming: '/wyoming',
    },
  },

  // Others
  pages: '/pages',
  docs: 'https://zone-docs.vercel.app',
  playground: '/playground',
  license: 'https://material-ui.com/store/license/#i-standard-license',
  minimalStore: 'https://material-ui.com/store/items/minimal-dashboard',
  zoneStore: 'https://mui.com/store/items/zone-landing-page/',
  figmaPreview:
    'https://www.figma.com/file/X6OyiGHF8dnTk3aT38P0oN/%5BPreview%5D-Zone_Web.30.03.23?node-id=0-1',
};
