import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CartProvider } from 'use-shopping-cart';

import App from './App';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <CartProvider
          // @ts-ignore
          mode="payment"
          cartMode="checkout-session"
          stripe={STRIPE_PUBLISHABLE_KEY || ''}
          currency="USD"
        >
          <App />
        </CartProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
