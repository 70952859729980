import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// ==============================|| AUTH GUARD ||============================== //

type Props = {
  children: React.ReactElement;
};
const AuthGuard = ({ children }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.localStorage.getItem('session-token') === 'null') {
      navigate('auth/login-cover', { replace: true });
    }
  }, [navigate]);

  return children;
};

export default AuthGuard;
